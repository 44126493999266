<template>
  <v-dialog :value="value" @input="onClose" persistent fullscreen max-width="600px" transition="dialog-bottom-transition">
    <v-card>
      <!-- 顶栏 -->
      <v-toolbar
        dark
        color="rgb(83, 109, 254)"
      >
        <v-btn
          icon
          dark
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ modeText }}神器</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="submit"
          >
            保存
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <!-- 名称 -->
              <v-text-field
                v-model="form.name"
                :rules="rules.name"
                label="神器名称"
                dense
                outlined
                required
              ></v-text-field>
              <!-- 图标 -->
              <v-file-input
                @change="onFileChange"
                label="神器图标"
                accept="image/png"
                prepend-icon=""
                append-icon="mdi-image"
                hide-details
                outlined
                dense
              ></v-file-input>
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="*神器图标应该是一个**16x16**像素的**PNG**图片，游戏会自动将过高分辨率的图片进行压缩"></tips-text>
              </div>
              <div class="d-flex justify-center my-2">
                <v-img
                  :src="form.image"
                  :aspect-ratio="1"
                  width="100px"
                  height="100px"
                  class="indigo lighten-5 flex-grow-0"
                ></v-img>
              </div>
              <!-- 装备槽位 -->
              <v-select
                v-model="form.slot"
                :items="slotList"
                :rules="rules.slot"
                label="装备槽位"
                item-text="label"
                item-value="value"
                dense
                outlined
              ></v-select>
              <!-- 装备品质 -->
              <v-select
                v-model="form.quality"
                :items="qualityList"
                :rules="rules.quality"
                label="装备品质"
                item-text="label"
                item-value="value"
                dense
                outlined
              ></v-select>
            </v-col>
            <!-- 第二列 -->
            <v-col cols="6">
              <!-- 所属套装 -->
              <v-select
                v-model="form.part"
                :items="partList"
                label="所属套装"
                item-text="label"
                item-value="value"
                outlined
                dense
              ></v-select>
              <!-- 类型 -->
              <v-select
                v-model="form.oriented"
                :items="orientedList"
                label="神器类型"
                item-text="label"
                item-value="value"
                hide-details
                outlined
                dense
              ></v-select>
              <!-- 提示 -->
              <div class="d-flex mt-2">
                <v-spacer></v-spacer>
                <tips-text text="*决定了神器会在**黑市**还是**神器铁匠铺**刷新，以及对**四翼天使**的影响"></tips-text>
              </div>
              <!-- 特效加成 -->
              <v-text-field
                v-model="form.firstBonusSize"
                :rules="rules.size"
                label="第一加成等级"
                type="number"
                dense
                outlined
                required
              ></v-text-field>
              <v-select
                v-model="form.firstBonusEffect"
                :items="effectList"
                label="第一加成特效"
                item-text="label"
                item-value="value"
                dense
                outlined
                required
              ></v-select>
              <!-- 特效加成 -->
              <v-text-field
                v-model="form.secondBonusSize"
                :rules="rules.size"
                label="第二加成等级"
                type="number"
                dense
                outlined
                required
              ></v-text-field>
              <v-select
                v-model="form.secondBonusEffect"
                :items="effectList"
                label="第二加成特效"
                item-text="label"
                item-value="value"
                dense
                outlined
                required
                hide-details
              ></v-select>
              <!-- 提示 -->
              <div class="d-flex my-2">
                <v-spacer></v-spacer>
                <tips-text text="*加成等级与加成特效是**选填**的，当加成等级为**0**或**非数字**时，将会**自动忽略**加成特效"></tips-text>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import { mapGetters, mapMutations, mapState } from 'vuex'
import MixinModAssets from 'mixins/MixinModAssets'
import { fileToBase64 } from 'utils/index'
import TipsText from './TipsText.vue'

function getDefaultForm () {
  return {
    name: '',
    image: '',
    slot: 1,
    quality: 1,
    firstBonusSize: '1',
    firstBonusEffect: 'Attack',
    secondBonusSize: '1',
    secondBonusEffect: 'Defense',
    part: 0,
    oriented: 0
  }
}

const rules = {
  name: [
    v => !!v || '神器名称必填'
  ],
  slot: [
    v => !!v || '装备槽位必选'
  ],
  quality: [
    v => !!v || '装备品质必选'
  ],
  size: [
    v => (!!v && parseInt(v)) ? true : '加成等级必填'
  ],
  effect: [
    v => !!v || '加成特效必选'
  ]
}

export default {
  mixins: [MixinModAssets],
  components: { TipsText },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  data () {
    return {
      rules,
      form: getDefaultForm()
    }
  },
  computed: {
    ...mapState('mod', ['artifacts']),
    ...mapGetters('mod', ['artifactEffects', 'skillNames', 'artifactSlots', 'artifactQualities', 'artifactParts']),
    isEdit () {
      return this.id !== null
    },
    modeText () {
      return this.isEdit ? '编辑' : '创建'
    },
    // 所属套装的 hint
    // TODO: BUG
    // partHint () {
    //   return this.partList[this.form.part].desc
    // }
    // slot quality effect part orien
    allEffect () {
      return {
        ...this.artifactEffects,
        ...this.skillNames
      }
    },
    slotList () {
      return Object.values(this.artifactSlots)
    },
    qualityList () {
      return Object.values(this.artifactQualities)
    },
    effectList () {
      return Object.values(this.allEffect)
    },
    partList () {
      return Object.values(this.artifactParts)
    },
    orientedList () {
      return Object.values(this.$mod.Oriented)
    }
  },
  watch: {
    id () {
      this.updateForm()
    }
  },
  methods: {
    ...mapMutations('mod', ['addArtifact', 'editArtifact']),
    submit () {
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        return
      }
      if (!this.form.image) {
        this.$noti.error('请提供神器图标')
        return
      }
      const artifact = { ...this.form }
      const firstSize = parseInt(artifact.firstBonusSize)
      const firstEffect = artifact.firstBonusEffect
      const secondSize = parseInt(artifact.secondBonusSize)
      const secondEffect = artifact.secondBonusEffect
      // 格式化数据
      if (isNaN(firstSize) || !firstEffect) {
        artifact.firstBonusSize = 0
        artifact.firstBonusEffect = ''
      }
      if (isNaN(secondSize) || !secondEffect) {
        artifact.secondBonusSize = 0
        artifact.secondBonusEffect = ''
      }
      // 为新增的数据添加 id
      if (!artifact.id) {
        artifact.id = nanoid()
      }
      // 根据模式选择 mutation
      const mutation = this.isEdit ? this.editArtifact : this.addArtifact
      mutation({
        id: artifact.id,
        artifact
      })
      this.$noti.success(`${this.modeText}成功`)
      this.onClose()
    },
    updateForm () {
      if (!this.isEdit) {
        this.form = getDefaultForm()
        return
      }
      const item = this.artifacts.find(item => item.id === this.id)
      this.form = {
        ...item
      }
    },
    onFileChange (file) {
      fileToBase64(file).then(base64 => {
        this.form.image = base64
      })
    },
    onClose () {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
