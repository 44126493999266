<template>
  <v-col lg="4" md="12" xs="12" cols="12">
    <v-card class="card">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title>
            <!-- 品质 -->
            <v-chip class="mr-2" small :color="qualityColors[quality.value]" dark>{{ quality.label }}</v-chip>
            <!-- 名称 -->
            {{ artifact.name }}
          </v-card-title>
          <v-card-text>
            <!-- 套装 -->
            <div class="text-overline d-flex">
              <v-chip
                class="mb-2"
                :color="partColors[part.value]"
                small
                dark
              >
                <v-icon left>
                  mdi-fire
                </v-icon>
                {{ part.label }}
              </v-chip>
            </div>
            <div class="text-subtitle-1 mb-2">
              {{ oriented.label }}
              {{ slot.label }}
            </div>
            <!-- 加成一 -->
            <div class="text-caption mb-1 d-flex">
              <template v-if="firstBonusSize">
                <div class="">{{ firstBonusSize }}&nbsp;</div>
                <div class="">{{ firstBonusEffect.label }}</div>
              </template>
              <div v-else>暂无特效加成</div>
            </div>
            <!-- 加成二 -->
            <div class="text-caption d-flex">
              <template v-if="secondBonusSize">
                <div class="">{{ secondBonusSize }}&nbsp;</div>
                <div class="">{{ secondBonusEffect.label }}</div>
              </template>
              <div v-else>暂无特效加成</div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex">
            <v-btn color="primary" depressed outlined small @click="onEdit">编辑</v-btn>
            <!-- TODO: 删除弹窗 -->
            <v-btn color="error" depressed outlined small @click="onDelete">删除</v-btn>
          </v-card-actions>
        </div>
        <v-avatar
          class="ma-3"
          size="125"
          tile
        >
          <v-img :src="artifact.image"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { mapMutations } from 'vuex'
import MixinModAssets from 'mixins/MixinModAssets'

const qualityColors = {
  1: 'blue-grey',
  2: 'info',
  3: 'purple',
  4: 'orange',
  5: 'success'
}

const partColors = {
  0: 'blue-grey',
  1: 'light-blue',
  2: 'teal',
  3: 'amber',
  4: 'brown',
  5: 'lime',
  6: 'light-green lighten-2',
  7: 'indigo darken-3'
}

export default {
  mixins: [MixinModAssets],
  props: {
    artifact: {
      type: Object,
      required: true
    }
  },
  computed: {
    slot () {
      return this.$mod.Slot[this.artifact.slot]
    },
    quality () {
      return this.$mod.Quality[this.artifact.quality]
    },
    part () {
      return this.$mod.Part[this.artifact.part]
    },
    oriented () {
      return this.$mod.Oriented[this.artifact.oriented]
    },
    firstBonusSize () {
      return this.formatSize(this.artifact.firstBonusSize)
    },
    secondBonusSize () {
      return this.formatSize(this.artifact.secondBonusSize)
    },
    allEffect () {
      return {
        ...this.$mod.Effect,
        ...this.$mod.Skill
      }
    },
    firstBonusEffect () {
      const effect = this.artifact.firstBonusEffect
      return this.allEffect[effect]
    },
    secondBonusEffect () {
      const effect = this.artifact.secondBonusEffect
      return this.allEffect[effect]
    },
    qualityColors () {
      return qualityColors
    },
    partColors () {
      return partColors
    }
  },
  methods: {
    ...mapMutations('mod', ['deleteArtifact']),
    formatSize (val) {
      const size = parseInt(val)
      if (isNaN(size) || size === 0) return 0
      else if (size > 0) return `+${size}`
      else return `${size}`
    },
    onEdit () {
      this.$emit('edit', this.artifact.id)
    },
    onDelete () {
      this.deleteArtifact({
        id: this.artifact.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
