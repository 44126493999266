<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-10 sheet rounded-lg mb-10">
          <div class="header d-flex mb-2">
            <div class="text-h4">神器</div>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="addArtifact">
              <v-icon class="mr-2">mdi-plus</v-icon>
              新增
            </v-btn>
          </div>
          <v-divider class="mb-2"></v-divider>
          <empty tips="暂无神器，可点击右上方按钮添加~" v-if="artifacts.length === 0"></empty>
          <template v-else>
            <v-row class="my-4">
              <artifact-card
                v-for="artifact in artifacts"
                :key="artifact.id"
                :artifact="artifact"
                @edit="editArtifact"
              ></artifact-card>
            </v-row>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
    <edit-artifact v-model="dialog" :id="id"></edit-artifact>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ArtifactCard from 'components/mod/ArtifactCard.vue'
import EditArtifact from 'components/mod/EditArtifact.vue'

export default {
  components: {
    ArtifactCard,
    EditArtifact
  },
  data () {
    return {
      dialog: false,
      id: null
    }
  },
  computed: {
    ...mapState('mod', ['artifacts'])
  },
  methods: {
    addArtifact () {
      this.dialog = true
      this.id = null
    },
    editArtifact (id) {
      this.dialog = true
      this.id = id
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  .add {
    position: absolute;
    right: 16px;
    bottom: -50%;
  }
}
</style>
